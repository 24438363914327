/*
 * @Author: cuixuecheng
 * @Date: 2023-10-24 19:39:14
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2023-10-24 19:40:48
 * @Description: 
 */
import PhoneBar from './components/phoneBar.js';
import './App.css';
import Login from './components/login.js';
import { useState } from 'react';

function App() {
  const [login, setLogin] = useState(false)
  const onLogin = (value) => {
    console.log('onLogin', value)
    setLogin(value)
  }
  // useEffect(()=>{
  //   console.log(login)
  // },[login])
  return (
    <div className="App">
      {!login ? <Login onLogin={onLogin}/> : <PhoneBar/>}
    </div>
  );
}

export default App;
