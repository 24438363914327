/*
 * @Author: cuixuecheng
 * @Date: 2023-10-24 11:12:47
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2023-10-24 19:50:11
 * @Description: 
 */
// import { useEffect, useState } from 'react'
import { Button, Input} from 'antd'
import { useState } from 'react'
import './style.css'

export default function PhoneBar() {
  const [dialoutNumber, setDialoutNumber] = useState('')

  const numberInputChange = (event) => {
    console.log(event.target.value)
    setDialoutNumber(event.target.value)
  }

  const dialout = () => {
    if (!dialoutNumber) {
      alert('请填写外呼号码')
      return
    }
    if (window.SPhone) {
        window.SPhone.callApi.dialout({
          calleeNumber: dialoutNumber,
          success: (res) => {
              // success('外呼成功')
          },
          fail: (error) => {
              error(error.message)
          }
        })
    }
  }
  const hangup = () => {
    if (window.SPhone) {
        window.SPhone.callApi.hangup({
            fail: function (error) {
                console.log(error, 'error')
            }
        })
    }
  }
  const holdOrUnHold = (type) => {
      if (window.SPhone) {
          window.SPhone.callApi.holdOrUnHold({
              type: type,
              fail: function (error) {
                  console.log(error, 'error')
              }
          })
      }
  }
  const muteOrUnMute = (type) => {
      if (window.SPhone) {
          window.SPhone.callApi.muteOrUnMute({
              type: type,
              fail: (error) => {
                  console.log(error, 'error')

              }
          })
      }
  }
  const accept = () => {
      window.SPhone.webPhoneApi.accept()
  }
  const connect = () => {
      window.SPhone.webPhoneApi.connect()
  }
  const disconnect = () => {
      window.SPhone.webPhoneApi.disconnect()
  }
  const sendDTMF = (key) => {
    window.SPhone.webPhoneApi.sendDTMF(key)
  }
  const isVisibleBtn = () => {
    console.log('1111')
    return  true
  }
  const keyList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#']
  return (
    <div className="softphone">
      <div className="state">
          {/* <Select value={agentStatusNumber} placeholder='请选择状态' style={{ width: '150px' }} onChange={updateAgentStatus} className={!isVisibleBtn('stateSelect')? 'hide' : ''}>
              {
                  agentStatusList.map((item) => {
                      return <Option key={item.number} value={item.number}>{item.name}</Option>
                  })
              }
          </Select> */}
          {/* <span className='statusName'>{currentStatusName}</span> */}
          {/* <PhoneBarTimes ref = 'phoneBarTimeRef'></PhoneBarTimes> */}
          {/* <Select value={loginType} placeholder='切换登陆方式' style={{ width: '150px' }} onChange={updateLoginType} >
              {
                  loginTypeList.map((item) => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                  })
              }
          </Select> */}
          <Button type="primary" disabled={!isVisibleBtn('disconnect')} onClick={() => disconnect()}>
              断开设备
          </Button>
          <Button type="primary" disabled={!isVisibleBtn('connect')} onClick={() => connect()}>
              重连设备
          </Button>
      </div>
      <div className='oprate'>
          <Input style={{ width: '150px' }} placeholder='请输入号码'  onChange={numberInputChange}></Input>
          <Button type="primary" disabled={!isVisibleBtn('dialout')} onClick={() => dialout()}>
              外呼
          </Button>
          <Button type="primary" disabled={!isVisibleBtn('hangup')} onClick={() => hangup()}>
              挂机
          </Button>
          <Button type="primary" disabled={!isVisibleBtn('hold')} onClick={() => holdOrUnHold('1')}>
              保持
          </Button>
          <Button type="primary" disabled={!isVisibleBtn('holdcancel')} onClick={() => holdOrUnHold('2')}>
              取消保持
          </Button>
          <Button type="primary" disabled={!isVisibleBtn('mute')} onClick={() => muteOrUnMute('1')}>
              静音
          </Button>
          <Button type="primary" disabled={!isVisibleBtn('mutecancel')} onClick={() => muteOrUnMute('2')}>
              取消静音
          </Button>
          <Button type="primary" disabled={!isVisibleBtn('answer')} onClick={() => accept()}>
              接听
          </Button>
      </div>
      <div className='dtmf'>
          <div className="dtmf-tit">webrtc拨号盘</div>
          <div className="dtmf-box">
              {
                  keyList.map((key) => {
                      return <Button
                          type="primary"
                          key={key}
                          disabled={!isVisibleBtn('key')}
                          onClick={() => sendDTMF(key)}
                      >{key}</Button>
                  })
              }
          </div>
      </div>
    </div>
  )
}

