/*
 * @Author: cuixuecheng
 * @Date: 2023-10-24 11:43:32
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2023-10-25 11:38:40
 * @Description: 
 */
import { Button, Form, Input } from 'antd';
import SPhone from 'sphone-sdk'

const style = {
  marginTop: '10px'
}
export default function Login({onLogin}) {
  const onFinish = (values) => {
    window.SPhone = new SPhone({
      server: 'https://kf.dev1.xinmuai.net/api',
      agentNumber:values.agentNumber,
      accountId: values.accountId,
      password: values.password,
      debug: true,
      loginType: 'WEBRTC',
      error: (error) => {
        alert('登录失败')
        console.log(error)
      },
      success: (agentInfo) => {
        console.log(agentInfo)
      }
    })
    window.SPhone.attachEvent({
      success: (msg) => {
        console.log(msg)
        onLogin(true)
        // getAgentPhoneBarList() // 获取电话条状态配置
        // 暂无 getAvailableSipNumberList() // 切换sip话机的时候，获取可动态绑定的sip号列表
        // phoneBarTimeRef.value.timerTypeChange({ timeType: 'timing', statusTime: 0 })
      },
      error: (error) => {
        console.error(error)
      },
      message: (res) => {
        const events = res.event
        console.log(events)
        
      }
    })
    // onLogin(true)
    console.log('Success:', values);
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return(
    
    <div style={style} >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="座席"
          name="agentNumber"
          rules={[{ required: true, message: 'Please input your agentNumber!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="账户"
          name="accountId"
          rules={[{ required: true, message: 'Please input your accountId!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="密码"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>


        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>

  )
}